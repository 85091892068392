body, #root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.public-container {
  flex: 1;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.public-container::before {
  content: "";
  position: fixed;
  top: -5%;
  left: -5%;
  right: -5%;
  z-index: -1;

  background-image: url('../public/background.jpg');
  background-size: cover;
  background-position: center;
  display: block;
  background-size:cover;
  width: 110%;
  height: 110%;

  filter: blur(4px);
}


.navbar-brand {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.navbar-brand h3 {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  font-size: 1.2rem;
  font-weight: 800;
  margin-left: 5px;
}

.bi-light path, .bi-light polygon, .bi-light rect {
  fill: #f1f1f1;
}

.public-header .bi-light path, .public-header .bi-light polygon, .public-header .bi-light rect {
  fill: #000;
}